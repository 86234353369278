.notistack-MuiContent{
    width: 100%;
}

.notistack-SnackbarContainer{
    top: 100px!important;
}

#notistack-snackbar{
    width: 100%;
}

*:focus-visible {
    outline: none;
}

.ms_fade-in{
    opacity: 1!important;
    transition: all .2s linear;
}

.change-animation,
.change-animation .MuiSlider-valueLabelLabel{
    animation-name: change-animation;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-iteration-count: 5;
}

@keyframes change-animation {
    0% {
        color: inherit;
    }

    50% {
        color: #C8D600;
    }

    100%{
        color: inherit
    }
}

.custom_tour{
    min-width: 190px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 2px solid #F0FF32;
}

.simplebar-track.simplebar-vertical{
    width: 10px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before{
    background: rgba(9, 71, 84, 1)
}
